import deepmerge from 'deepmerge';

export const setupLocale = async (locale?: string) => {
  const userLocaleMessages = await import(`./locales/${locale}.json`);
  const defaultLocaleMessages = await import(`./locales/en.json`);
  const messages = deepmerge(
    defaultLocaleMessages.default,
    userLocaleMessages.default
  );

  return messages;
};

export const getLanguages = () => [
  {
    id: 'fr',
    name: 'Français',
    icon: '/assets/icons/flags/ic_flag_fr.svg',
  },
  {
    id: 'en',
    name: 'English',
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
];
