import { useTranslations, useLocale } from 'next-intl';

export default function useLocales() {
  const t = useTranslations();
  const locale = useLocale();

  return {
    translate: (key: any, options?: any) => t(key, options),
    locale,
  };
}
